var React = require('react');
var ReactDOM = require('react-dom');
import { useState, useRef, useEffect, useCallback } from 'react'
import PaymentCard from './PaymentCard';
import  * as command from "../../../command.controller";
import * as tools from '../../../service/tools';

const Payment =  (props, ref) => {
    
    const [payment, setPayment] = useState([]);

    /*********************************************************************************
             Durante il caricamento chiede all' API il dettaglio del socio/cliente     
    **********************************************************************************/
    useEffect ( () => { 
        console.log(props.paymentId);
        let paymentSelectedId = props.paymentId;
        command.sendCommand('showpayment', {'searchkey':paymentSelectedId}).then((res) => {
            res = tools.singleObjectToReactArray(res);
            console.log("detailed: ", res);
            setPayment ( [res] );
        }).catch((e) => {
            // Se si verifica un'eccezione, stampa il messaggio di errore nella console
            console.log(e.message);
        });
    }, []);

    return (
        <div className="row align-items-start">
            <div className="col-md-2 col-lg-2 col-xl-2 col-xxl-2"></div>
            <div className="col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                <div style={{height:100+'px'}}>
                    <PaymentCard cardcontent={payment} {...props} />
                </div>
            </div>
        </div>
    );
}
export default React.forwardRef(Payment);


