var React = require('react');
var ReactDOM = require('react-dom');
import { useState, useRef, useEffect } from 'react'
import HeaderLayout from '../base/HeaderLayout';
import Mainmenu from '../components/Mainmenu';
import Searchbar from '../components/Searchbar'; 

import Welcome from '../components/Welcome';
import Footer from '../base/Footer';
import Advertising from '../base/Advertising';
import MainContent  from '../base/MainContent'

import  * as funcs from "../../command.controller";
import * as toolfuncs from '../../service/tools';



export default function Main (props) {
      const [mainKey, setMainKey] = useState (0);
      const [customerID, setCustomerID] = useState (0);
      const [paymentID, setPaymentID] = useState (0);
      const [section, setSection] = useState('empty');
      const [customersContent, setCustomersContent] = useState([]);
      const [registryContent, setRegistryContent] = useState([]);
      const [paymentsContent, setPaymentsContent] = useState([]);
      const [result, setResult] = useState(null);
      const leftCRef = useRef(null);
      const centerCRef = useRef(null);
      const rightCRef = useRef(null);
      //const inputRef = useRef(null);
   
    /*********************************************************************************
            Ricarica i componenti a cui è assegnato il mainKey
    **********************************************************************************/
    const reloadComponent = () => {
      setMainKey(mainKey+1); // Serve per ricaricare il maincontent
    }
         
       
    /*********************************************************************************
          Questa funzione callback viene chiamata quando
          si seleziona un customer nella lista, riceve l'id dell'elemento
          selezionato                                         
    **********************************************************************************/
    const customerSelected = (itemID) => {
      setSection('customerDetails');
      setCustomerID(itemID);
      reloadComponent();
    }
    /*********************************************************************************
          Questa funzione callback viene chiamata quando
          si seleziona un customer nella lista, riceve l'id dell'elemento
          selezionato                                         
    **********************************************************************************/
  const paymentSelected = (itemID) => {
    console.log("the payment was selected: " + itemID);
    setSection('paymentDetails');
    setPaymentID(itemID);
    reloadComponent();
  }
  /*********************************************************************************
        Questa funzione viene chiamata quando si seleziona una voce
        nel menu a sinistra, riceve la sezione da abilitare ( lista, dettaglio)  
        La sezione viene impostata nel MainContent 
  **********************************************************************************/
  const menuSelection = (section) => {
    setSection(section);
    reloadComponent();
  }
  
  const submit = (item) => {
    console.log("press submit on detail form");
  }
  
  /*  Costruisce un oggetto con tutte le callbacks d passare al mainContent     */
  let callbacks = {'submitcallback' : submit, 'customerselectioncallback' : customerSelected, 'paymentselectioncallback' : paymentSelected,'wspacecallback': props.wspacecallback, 'menucallback': menuSelection, };
        
  return (
        <div className="container-fluid d-flex flex-column min-vh-100 white-textcolor black-backcolor" >
        <HeaderLayout {...props}>
          <Welcome /><Searchbar />
        </HeaderLayout>
        <MainContent  key={'content'+ mainKey} contentSection = {section}  mainwscallbacks={callbacks} customerID={customerID} paymentID={paymentID}/>
        <Advertising />
        <Footer />
      </div>
  );
}

