let React = require('react');
let ReactDOM = require('react-dom');
import { useState, useRef, useEffect, useMemo } from 'react'
import Customer from '../components/customers/Customer'; 
import CustomersList from '../components/customers/CustomersList';
import CustomersListSmall from '../components/customers/CustomersListSmall';
import Payment from '../components/payments/Payment'; 
import PaymentsList from '../components/payments/PaymentsList';
import PaymentsListSmall from '../components/payments/PaymentsListSmall';
import Leftmenu from '../components/Leftmenu';
import Empty from '../components/Empty';
import  * as funcs from "../../command.controller";



const  MainContent = (props, ref) => {

      //let customerID = 0;
      let page = 'customerslist';


      const [count, setCount] = useState(0);
      const [type, setType] = useState('');
      const [section, setSection] = useState(<Empty/>);
      const [customerSelected, setCustomerSelected] = useState(0);
      const [customerID, setCustomerID] = useState(0);
      const [registryContent, setRegistryContent] = useState([]);
      const detailsCRef = useRef(null);
      const listCRef = useRef(null);
      const customersListsmallCRef = useRef(null);
      const paymentsListsmallCRef = useRef(null);
      const menuCRef = useRef(null);
      const mainCRef = useRef(null);


      const customerSmallListItemSelected = (itemID) => {
            console.log(itemID);
      }
      const paymentSmallListItemSelected = (itemID) => {
            console.log(itemID);
      }

      /*********************************************************************************
            Preparazione dei componenti durante il load del MainConetent      
      **********************************************************************************/
      let empty = <Empty/> 
      let customerDetails = <>
                              <div className="row h-100">
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 overflow-auto" >
                                          <div ref={ref} className="mh-10 " style={{height:100+'px'}}>
                                                <CustomersListSmall ref={customersListsmallCRef} key={'customerslistsmall'+count} {...props} maincontentcallback={customerSmallListItemSelected} customerId={props.customerID}></CustomersListSmall>
                                          </div>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
                                          <Customer ref={detailsCRef} key={'costumerdetails'} customerId={props.customerID} {...props} style={{position:'absolute', top: 0+'px'}}></Customer>
                                    </div>
                              </div>
                              </>;
      let customerList = <CustomersList ref={listCRef} key={'customerslist'+count} {...props}></CustomersList>
      let paymentDetails = <>
                              <div className="row h-100">
                                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 overflow-auto" >
                                          <div ref={ref} className="mh-10 " style={{height:100+'px'}}>
                                                <PaymentsListSmall ref={paymentsListsmallCRef} key={'paymentslistsmall'+count} {...props} maincontentcallback={paymentSmallListItemSelected} paymentId={props.paymentID}></PaymentsListSmall>
                                          </div>
                                    </div>
                                    <div className="col-sm-9 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                                          <Payment ref={detailsCRef} key={'paymentdetails'} paymentId={props.paymentID} {...props} style={{position:'absolute', top: 0+'px'}}></Payment>
                                    </div>
                              </div>
                              </>;
      let paymentsList = <PaymentsList ref={listCRef} key={'paymentslist'+count} {...props}></PaymentsList>;
      let customerPayments = <div></div>;
      console.log("main loaded",props.customerID);
            
            
      /*********************************************************************************
            Questo frammento di codice utilizza il hook useEffect di React
            per effettuare un'azione dopo che il componente
            è stato montato o aggiornato. In questo caso chiama il selettore
            di sezione.
      **********************************************************************************/
      useEffect ( () => { 
            sectionChanger(props.contentSection);
      }, []);
      

     
      /*********************************************************************************
            Questa funzione viene chiamata quando si seleziona un comando nel menu          
            quando il componente viene montato per abilitare o meno una sezione
            ( Si potrebbe ovviare mettendola in useEffect  )
      **********************************************************************************/
      const sectionChanger = (s) => {
            switch (s) {
                  case 'registry': 
                        setSection(customerList)
                  break;
                  case 'customerDetails':
                        setSection(customerDetails);
                        break;
                  case 'payments':
                        setSection(paymentsList);
                        break;
                  case 'customerPayments':
                        setSection(customerPayments);
                        break;
                  case 'paymentDetails':
                        setSection(paymentDetails);
                        break;
                  case 'empty':
                        setSection(empty);
                  default:
                        setSection(empty);
            }
      }


      return (
            <>
            <div className="row  align-items-center white-textcolor" style={{height:1.5 +'em'}} >
            </div>
            <div className="row flex-grow-1" >
                  <div className="col-md-2 col-lg-2 col-xl-1 col-xxl-1 lightdark-backcolor overflow-auto" key={'div'+count}>
                        <Leftmenu ref={menuCRef}  {...props}></Leftmenu>
                  </div>
                  <div className="col-md-10 col-lg-10 col-xl-11 col-xxl-11 middark-backcolor overflow-auto">
                        {section /* Questa sezione è dinamica */} 
                  </div>
            </div>
            </>
      );
}

export default React.forwardRef(MainContent);

