let React = require('react');
let ReactDOM = require('react-dom');
import { useState, useRef, useEffect } from 'react'
import HeaderLayout from '../base/HeaderLayout';
import Mainmenu from '../components/Mainmenu';
import Searchbar from '../components/Searchbar'; // class
import Welcome from '../components/Welcome'; // class
import Footer from '../base/Footer';


import  * as funcs from "../../command.controller";
import * as toolfuncs from '../../service/tools';



export default function InsertWs (props) {

      const [count, setCount] = useState(0);
      let [result, setResult] = useState(null);
      const centerCRef = useRef(null);
      const [rightContent, setRightContent] = useState([]);
      const [leftContent, setLeftContent] = useState([]);




      useEffect(() => {
            // Update the document title using the browser API
            document.title = `You clicked ${count} times`;
          });
         
          /* Questa funzione viene chiamata dalla selezione del menu principale */
           const commandSelection = (command) => {
            setCount(count +1);
            console.log("button on menu was clicked");
            funcs.sendCommand(command).then((res) => {
              res = toolfuncs.objToReactString(res);
              setLeftContent(res);
            }).catch((e)=>{
              console.log(e.message)});
        }
          
          let paymentCallbacks = {'wspacecallback': props.wspacecallback, 'commandcallback': commandSelection};
          let contents = {'left' : leftContent ,  'right' : rightContent};

        
      
      

      return (
            <div className="container-fluid vh-100 vw-100 d-flex flex-column white-textcolor black-backcolor" >
            <HeaderLayout>
              <Welcome /><Searchbar /><Mainmenu callbacks={paymentCallbacks} />
            </HeaderLayout>
            
            <Footer />
          </div>
      );
}

