
var React = require('react');
var ReactDOM = require('react-dom');
import * as menu from "../../menu.controller";

export default function  Leftmenu (props) {

  /*********************************************************************************
            Gestisce il click nel menu  
  **********************************************************************************/
  const handleCommandClick = (menuItem) => () => {
    //console.log("presss!!!", props.appcallbacks );
    let section = menu.decodeMenu(menuItem)
    props.mainwscallbacks.menucallback(section);
  };

  const handleWspaceClick = (str) => () => {
    //console.log("presss wspace!!!");
    props.mainwscallbacks.wspacecallback(str);
  };

  return (
    <>
      <div className="row  align-items-left white-textcolor"><br/></div>
      <div className="row  align-items-left white-textcolor">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              Società
            </button>
          </h2>
          <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <ul>
                <li role="button">Dati società</li>
                <li>Collaboratori</li>
                <li>Staff Sportivo</li>
                <li>Calendario</li>
                <li>Sito Web</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row  align-items-left white-textcolor">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
              Tesserati
            </button>
          </h2>
          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <ul>
                <li role="button" onClick={handleCommandClick('datianagrafici')}>Dati anagrafici</li>
                <li role="button" onClick={handleCommandClick('gruppi')}>Gruppi</li>
                <li role="button" onClick={handleCommandClick('discipline')}>Discipline</li>
                <li></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row  align-items-left white-textcolor">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
              Contabilità
            </button>
          </h2>
          <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <ul>
                <li role="button" onClick={handleCommandClick('daticontabili')}>Dati contabili</li>
                <li role="button" onClick={handleCommandClick('pagamenti')}>Pagamenti</li>
                <li role="button" onClick={handleCommandClick('????')}>????</li>
                <li></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}