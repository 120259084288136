var React = require('react');
var ReactDOM = require('react-dom');

const PaymentDetailsForm =  (props, ref) => {

    console.log("fields: ", props.formcontent.length);

    /* PROBLEMI CON DEFAULT VALUE che non si aggiorna risolto utilizzando l'attributo KEY:
    Quando KEY cambia, react ricrea l'istanza del componente e quindi forza nuovamente il valore di defaultValue.  
    L'utilizzo dell'attributo "defaultValue" al posto di "value" è dovuto al fatto che con il solo value il componente non è editabile */

    var fields = [
        'id',
        'data_pagamento',
        'cognome',
        'nome',
        'minore',
        'importo',
        'nricevuta',
        'emessa',
        'periodo'
    ];


    const handleSubmit = (evt) => {
        evt.preventDefault();
        console.log('You clicked submit.', evt.target[4].value);
        props.callbacks.submitcallback();
    };

    const handleChange = (evt) => {
        console.log(evt.target.value);
        //props.itemcallback();
    };

    const handleClick = (evt) => {
        console.log("clickkk!");
        //props.itemcallback();
    };

    return (
        <div ref={ref}>
            <form onSubmit={evt => handleSubmit(evt)}>
                {props.formcontent.map((item, index) =>
                    <div className="input-group input-group-sm mb-3" key={'field-'+index}>
                        <span className="input-group-text" id="inputGroup-sizing-sm">{fields[index]}</span>
                        <input type="text" className="form-control" key={item} defaultValue={item} onChange={evt => handleChange(evt)} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-xs" />
                    </div>              
                )}
                <button type="submit" onClick={evt => handleClick(evt)} className="btn btn-primary">Modifica</button>
            </form>
           
        </div>
    );
}
export default React.forwardRef(PaymentDetailsForm);



