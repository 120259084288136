var React = require('react');
var ReactDOM = require('react-dom');
import DetailForm from './CustomerDetailsForm';

const CustomerCard =  (props, ref) => {
    //console.log("customer card content ", props.cardcontent)

    const handleClick = () => () => {
        // Close the window and go to wsmain
        props.mainwscallbacks.menucallback('datianagrafici');
     };
    return (
        <div ref={ref}  >
            {props.cardcontent.map((item, index) =>
                <div className="card" key={'card-'+index} > <span className=" text-end fs-1 font-monospace text-dark " onClick={handleClick()}>X</span>
                    <div className="card-body">
                        <h5 className="card-title text-bg-dark">Iscritto/Allievo {item[3]} {item[4]}</h5>
                        <h6 className="card-subtitle mb-2 text-muted"><span> </span></h6>
                        <DetailForm formcontent={item} {...props} />
                    </div>
                </div>
            )}
        </div>
    );
}
export default React.forwardRef(CustomerCard);