var React = require('react');
var ReactDOM = require('react-dom');
import { useState, useRef, useEffect, useCallback } from 'react'
import  * as command from "../../../command.controller";

const CustomersListSmall =  (props, ref) => {

    const [customersList, setCustomersList] = useState([]);
    const table = useRef(null);
   

   
    
    /*********************************************************************************
            Durante il caricamento chiede all' API l'elenco di  tutti i clienti/soci     
    **********************************************************************************/
    useEffect ( () => { 
        command.sendCommand('showallcustomers').then((res) => {
            console.log("detailed: ", res);
            setCustomersList ( res );
        }).catch((e) => {
            // Se si verifica un'eccezione, stampa il messaggio di errore nella console
            console.log(e.message);
        });
    }, []);
    
    
    /*********************************************************************************
            Gestisce il click di selezione chiamando la callback nel workspace    
    **********************************************************************************/
    const handleClick = (itemClicked, type) => () => {
       props.mainwscallbacks.customerselectioncallback(itemClicked, type); 
       //props.maincontentcallback(itemClicked);
       console.log(table.current.scrollTop);
    };

    const row = () => {
        let row = [];
        customersList.map(customer =>  {
            if (customer.id == props.customerID)             
            row.push (<tr scope="row"  key={customer.id}  onClick={handleClick(customer.id, 'customer')}>
                <td className="text-bg-dark text-left"  style={{border: 2+"px solid"}}>{customer.surname + " "+customer.name}</td>    
            </tr>);
            else 
            row.push (<tr scope="row"  key={customer.id}  onClick={handleClick(customer.id, 'customer')}>
                <td className="text-bg-dark text-left"  style={{border: 0+"px solid"}}>{customer.surname + " "+customer.name}</td>    
            </tr>);
        });
        return row;
    }

    
    return (    
                <table className="table table-dark table-striped table-sm" ref={table}>
                    <thead><tr className="text-left" ><th scope="col" className="text-bg-dark text-left">Cognome Nome</th></tr></thead>
                    <tbody>
                        {row()}   
                    </tbody>
                </table>
    );
}
export default React.forwardRef(CustomersListSmall);





