var React = require('react');
var ReactDOM = require('react-dom');
import { useState, useRef, useEffect, useCallback } from 'react'
import  * as command from "../../../command.controller";

const CustomersList =  (props, ref) => {
    const [customersList, setCustomersList] = useState([]);
    
    /*********************************************************************************
            Durante il caricamento chiede all' API l'elenco di  tutti i clienti/soci     
    **********************************************************************************/
    useEffect ( () => { 
        command.sendCommand('showallcustomers').then((res) => {
            console.log("detailed: ", res);
            setCustomersList ( res );
        }).catch((e) => {
            // Se si verifica un'eccezione, stampa il messaggio di errore nella console
            console.log(e.message);
        });
    }, []);
    
    
    /*********************************************************************************
            Gestisce il click di selezione chiamando la callback nel workspace    
    **********************************************************************************/
    const handleClick = (itemClicked, type) => () => {
        props.mainwscallbacks.customerselectioncallback(itemClicked, type);
    };

    
    if ( customersList == undefined ) {
        return (
            <div style={{marginTop:10+'px'}}>
                <h5 className="text-center text-danger">Fai una selezione nel menu a sinistra</h5>
            </div>
        );   
    }
    return (
        <div ref={ref}  style={{height:0+'px'}}>
                <table className="table table-dark table-striped table-sm">
                    <thead><tr className="text-left" ><th scope="col" className="text-bg-dark text-left">Cognome Nome</th><th scope="col">Tessera</th><th scope="col">Luogo di nascita</th><th scope="col">Data di nascita</th><th scope="col">Disciplina</th></tr></thead>
                    <tbody>
                    {customersList.map(customer => 
                        <tr scope="row" className="text-bg-dark text-left" key={customer.id} onClick={handleClick(customer.id, 'customer')}>
                            <td>{customer.surname + " "+customer.name}</td>
                            <td>{customer.card}</td>
                            <td>{customer.birthcity}</td>
                            <td>{customer.birthdate}</td>
                            <td>{customer.groups}</td>
                        </tr>)}
                    </tbody>
                </table>
        </div>
    );
}
export default React.forwardRef(CustomersList);





