

/**************************************************************************************************************
          Questo codice definisce una funzione che prende in input un parametro chiamato command.
          La funzione analizza il valore di command e
          restituisce una stringa che rappresenta l'URL di un'API corrispondente.
*************************************************************************************************************/
const decodeMenu = (command) => {
    let section = '';
    // Utilizza uno statement switch per confrontare il valore di "command" con diversi casi
    switch (command) {
      case 'datianagrafici':
        section = 'registry';
        break;
      case 'gruppi':
        section = '';
        break;
      case 'discipline':
          section = '';
          break;
      case 'daticontabili':
        section = 'coustomerPayments';
        break;
      case 'pagamenti':
          section = 'payments';
          break;
      default:
        // Se "command" non corrisponde a nessun caso sopra, assegna una stringa vuota
        section = 'empty';
    }
    // Restituisce l'URL assegnato"
    return section;
  }

export {decodeMenu}


    /*
    const xhr = new XMLHttpRequest();
    //xhr.open("POST", 'http://api-gestionedanza.it/', true);
    xhr.open("GET", 'http://localhost:3000/'+apiUrl, true);
    //Send the proper header information along with the request
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = () => { // Call a function when the state changes.
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        response = xhr.responseText;
        //console.log(response);
        return response;
      }
    }
    xhr.send();
    */

