var React = require('react');
var ReactDOM = require('react-dom');
import { useState, useRef, useEffect, useCallback } from 'react'
import  * as command from "../../../command.controller";

const CustomerDetailsForm =  (props, ref) => {

    const [groupsList, setGroupsList] = useState([]);

    //console.log("form: ",props.formcontent);

    /* PROBLEMI CON DEFAULT VALUE che non si aggiorna risolto utilizzando l'attributo KEY:
    Quando KEY cambia, react ricrea l'istanza del componente e quindi forza nuovamente il valore di defaultValue.  
    L'utilizzo dell'attributo "defaultValue" al posto di "value" è dovuto al fatto che con il solo value il componente non è editabile */

    var fields = [
        'id',
        'tessera',
        'cf',
        'cognome',
        'nome',
        'luogo_nascita',
        'data_nascita',
        'indirizzo',
        'disciplina',
        'tutore',
        'cftutore',
        'mail',
        'telefono',
        'attivo',
        'privacy'
    ];


    /* Prelevare i gruppi dal database e alimentare option/select  */

    /*********************************************************************************
            Durante il caricamento chiede all' API l'elenco dei gruppi   
    **********************************************************************************/
    useEffect ( () => { 
        command.sendCommand('getallgroups').then((res) => {
            console.log( res );
            setGroupsList ( res );
        }).catch((e) => {
            // Se si verifica un'eccezione, stampa il messaggio di errore nella console
            console.log(e.message);
        });
    }, []);

    const handleSubmit = (evt) => {
        evt.preventDefault();
        console.log('You clicked submit.', evt.target[4].value);
        props.callbacks.submitcallback();
    };

    const handleChange = (evt) => {
        console.log(evt.target.value);
        //props.itemcallback();
    };

    const handleClick = (evt) => {
        console.log("clickkk!");
        //props.itemcallback();
    };

    /* Bisogna cambiare il componente di selezione disicplina con un option, come fare mettendo un if ? */ 

    return (
        <div ref={ref}>
            <form onSubmit={evt => handleSubmit(evt)}>
               
                <div className="input-group input-group-sm mb-3" key={fields[0]+'div'}>
                        <span className="input-group-text" id="inputGroup-sizing-sm">{fields[0]}</span>
                        <input type="text" className="form-control" key={fields[0]+'input'} defaultValue={props.formcontent[0]} onChange={evt => handleChange(evt)} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-xs" />
                </div>
                <div className="input-group input-group-sm mb-3"  key={fields[1]+'div'}>
                        <span className="input-group-text" id="inputGroup-sizing-sm">{fields[1]}</span>
                        <input type="text" className="form-control" key={fields[1]+'input'} defaultValue={props.formcontent[1]} onChange={evt => handleChange(evt)} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-xs" />
                </div>   
                <div className="input-group input-group-sm mb-3"  key={fields[2]+'div'}>
                        <span className="input-group-text" id="inputGroup-sizing-sm">{fields[2]}</span>
                        <input type="text" className="form-control" key={fields[2]+'input'} defaultValue={props.formcontent[2]} onChange={evt => handleChange(evt)} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-xs" />
                </div>
                <div className="input-group input-group-sm mb-3"  key={fields[3]+'div'}>
                        <span className="input-group-text" id="inputGroup-sizing-sm">{fields[3]}</span>
                        <input type="text" className="form-control" key={fields[3]+'input'} defaultValue={props.formcontent[3]} onChange={evt => handleChange(evt)} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-xs" />
                </div>
                <div className="input-group input-group-sm mb-3"  key={fields[4]+'div'}>
                        <span className="input-group-text" id="inputGroup-sizing-sm">{fields[4]}</span>
                        <input type="text" className="form-control" key={fields[4]+'input'} defaultValue={props.formcontent[4]} onChange={evt => handleChange(evt)} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-xs" />
                </div>
                <div className="input-group input-group-sm mb-3" key={fields[5]+'div'}>
                        <span className="input-group-text" id="inputGroup-sizing-sm">{fields[5]}</span>
                        <input type="text" className="form-control" key={fields[5]+'input'} defaultValue={props.formcontent[5]} onChange={evt => handleChange(evt)} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-xs" />
                </div>
                <div className="input-group input-group-sm mb-3"  key={fields[6]+'div'}>
                        <span className="input-group-text" id="inputGroup-sizing-sm">{fields[6]}</span>
                        <input type="text" className="form-control" key={fields[6]+'input'} defaultValue={props.formcontent[6]} onChange={evt => handleChange(evt)} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-xs" />
                </div>
                <div className="input-group input-group-sm mb-3"  key={fields[7]+'div'}>
                        <span className="input-group-text" id="inputGroup-sizing-sm">{fields[7]}</span>
                        <input type="text" className="form-control" key={fields[7]+'input'} defaultValue={props.formcontent[7]} onChange={evt => handleChange(evt)} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-xs" />
                </div>
                <div className="input-group input-group-sm mb-3" key={fields[8]+'div'}>
                        <span className="input-group-text" id="inputGroup-sizing-sm">{fields[8]}</span>
                        <select key={fields[8]+'select'}>
                            {groupsList.map((item, index) => 
                            <option key={item+'option'+index} value={item}>{item}</option>
                            )}   
                        </select>
                </div> 
                <div className="input-group input-group-sm mb-3" key={fields[9]+'div'}>
                        <span className="input-group-text" id="inputGroup-sizing-sm">{fields[9]}</span>
                        <input type="text" className="form-control" key={fields[9]+'input'} defaultValue={props.formcontent[9]} onChange={evt => handleChange(evt)} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-xs" />
                </div>
                <div className="input-group input-group-sm mb-3"  key={fields[10]+'div'}>
                        <span className="input-group-text" id="inputGroup-sizing-sm">{fields[10]}</span>
                        <input type="text" className="form-control" key={fields[10]+'input'} defaultValue={props.formcontent[10]} onChange={evt => handleChange(evt)} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-xs" />
                </div>   
                <div className="input-group input-group-sm mb-3"  key={fields[11]+'div'}>
                        <span className="input-group-text" id="inputGroup-sizing-sm">{fields[11]}</span>
                        <input type="text" className="form-control" key={fields[11]+'input'} defaultValue={props.formcontent[11]} onChange={evt => handleChange(evt)} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-xs" />
                </div>
                <div className="input-group input-group-sm mb-3"  key={fields[12]+'div'}>
                        <span className="input-group-text" id="inputGroup-sizing-sm">{fields[12]}</span>
                        <input type="text" className="form-control" key={fields[12]+'input'} defaultValue={props.formcontent[12]} onChange={evt => handleChange(evt)} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-xs" />
                </div>
                <div className="input-group input-group-sm mb-3"  key={fields[13]+'div'}>
                        <span className="input-group-text" id="inputGroup-sizing-sm">{fields[13]}</span>
                        <select key={fields[13]+'select'}>     
                            <option key={fields[13]+'option1'} value={'Y'}>{'Y'}</option>
                            <option key={fields[13]+'option2'} value={'N'}>{'N'}</option>
                        </select>
                </div>
                <div className="input-group input-group-sm mb-3" key={fields[14]+'div'}>
                        <span className="input-group-text" id="inputGroup-sizing-sm">{fields[14]}</span>
                        <select key={fields[14]+'select'}>     
                            <option key={fields[14]+'option1'} value={'Y'}>{'Y'}</option>
                            <option key={fields[14]+'option2'} value={'N'}>{'N'}</option>
                        </select>
                </div>
                <button type="submit" onClick={evt => handleClick(evt)} className="btn btn-primary">Modifica</button>
            </form>
           
        </div>
    );
}
export default React.forwardRef(CustomerDetailsForm);



