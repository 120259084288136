var React = require('react');
var ReactDOM = require('react-dom');
import { useState, useRef, useEffect, useCallback } from 'react'
import  * as command from "../../../command.controller";

const PaymentsListSmall =  (props, ref) => {

    const [paymentsList, setPaymentsList] = useState([]);
    const table = useRef(null);
   

   
    
    /*********************************************************************************
            Durante il caricamento chiede all' API l'elenco di  tutti i clienti/soci     
    **********************************************************************************/
    useEffect ( () => { 
        command.sendCommand('showallpayments').then((res) => {
            console.log("detailed: ", res);
            setPaymentsList ( res );
        }).catch((e) => {
            // Se si verifica un'eccezione, stampa il messaggio di errore nella console
            console.log(e.message);
        });
    }, []);
    
    
    /*********************************************************************************
            Gestisce il click di selezione chiamando la callback nel workspace    
    **********************************************************************************/
    const handleClick = (itemClicked, type) => () => {
       props.mainwscallbacks.paymentselectioncallback(itemClicked, type); 
       console.log(table.current.scrollTop);
    };

    const row = () => {
        let row = [];
        paymentsList.map((payment,index) =>  {
            if (payment.id == props.paymentID) 
            /* Evidenzia la selezione */            
            row.push (<tr scope="row"  key={payment.id+"-"+index}  onClick={handleClick(payment.id, 'payment')} style={{border: 2+"px solid"}}>
                <td className="text-bg-dark text-left"  style={{border: 0+"px solid"}}>{payment.payment_date}</td>
                <td className="text-bg-dark text-left"  style={{border: 0+"px solid"}}>{payment.surname}</td>
                <td className="text-bg-dark text-left"  style={{border: 0+"px solid"}}>{payment.amount}</td>    
            </tr>);
            else 
            row.push (<tr scope="row"  key={payment.id+"-"+index}  onClick={handleClick(payment.id, 'payment')} style={{border: 0+"px solid"}}>
                <td className="text-bg-dark text-left"  style={{border: 0+"px solid"}}>{payment.payment_date}</td>
                <td className="text-bg-dark text-left"  style={{border: 0+"px solid"}}>{payment.surname}</td>
                <td className="text-bg-dark text-left"  style={{border: 0+"px solid"}}>{payment.amount}</td>      
            </tr>);
        });
        return row;
    }
    return (    
            <table className="table table-dark table-striped table-sm" ref={table}>
                <thead><tr className="text-left" ><th scope="col" className="text-bg-dark text-left">Data</th><th scope="col" >Cognome</th><th scope="col" >Importo</th></tr></thead>
                <tbody>
                    {row()}   
                </tbody>
            </table>
    );
}
export default React.forwardRef(PaymentsListSmall);





