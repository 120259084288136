var React = require('react');
var ReactDOM = require('react-dom');


const Empty =  (props, ref) => {
    return (
        <div style={{marginTop:10+'px'}}>
            <h5 className="text-center text-danger">Fai una selezione nel menu a sinistra</h5>
        </div>
    );   
}
export default React.forwardRef(Empty);


