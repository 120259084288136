var React = require('react');
var ReactDOM = require('react-dom');
import { useState, useRef, useEffect, useCallback } from 'react'
import  * as command from "../../../command.controller";

const PaymentsList =  (props, ref) => {
    const [paymentsList, setPaymentsList] = useState([]);
    
    /*********************************************************************************
            Durante il caricamento chiede all' API l'elenco di  tutti i clienti/soci     
    **********************************************************************************/
    useEffect ( () => { 
        command.sendCommand('showallpayments').then((res) => {
            console.log("detailed: ", res);
            setPaymentsList ( res );
        }).catch((e) => {
            // Se si verifica un'eccezione, stampa il messaggio di errore nella console
            console.log(e.message);
        });
    }, []);
    
    
    /*********************************************************************************
            Gestisce il click di selezione chiamando la callback nel workspace    
    **********************************************************************************/
    const handleClick = (itemClicked, type) => () => {
        props.mainwscallbacks.paymentselectioncallback(itemClicked, type);
    };

    
    if ( paymentsList == undefined ) {
        return (
            <div style={{marginTop:10+'px'}}>
                <h5 className="text-center text-danger">Fai una selezione nel menu a sinistra</h5>
            </div>
        );   
    }
    return (
        <div ref={ref}  style={{height:0+'px'}}>
                <table className="table table-dark table-striped table-sm">
                    <thead><tr className="text-left" ><th scope="col" className="text-bg-dark text-left">Id</th><th scope="col">Data pagamento</th><th scope="col">Cognome Nome</th><th scope="col">Importo</th></tr></thead>
                    <tbody>
                    {paymentsList.map((payment,index) => 
                        <tr scope="row" className="text-bg-dark text-left" key={payment.id+"-"+index} onClick={handleClick(payment.id, 'payment')}>
                            <td>{payment.id}</td>
                            <td>{payment.payment_date}</td>
                            <td>{payment.surname + " "+ payment.name}</td>
                            <td>{"€ " + payment.amount + ",00"}</td>
                        </tr>)}
                    </tbody>
                </table>
        </div>
    );
}
export default React.forwardRef(PaymentsList);





