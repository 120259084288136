/* eslint-disable import/first */
var React = require('react');
var ReactDOM = require('react-dom');
import { Children } from 'react';


export default function HeaderLayout (props) {
      
      return (
            <>  
            <div className="row middark-backcolor mt-2 align-items-center">
                  <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1" />
                  <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1 syncopate-style">
                        <img className="image-small" style={{width:50 +'%' , height: 'auto' }} src="images/icona.png" />
                  </div>
                  <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 syncopate-style">
                        {props.children[0]}
                  </div>
                  
                  <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3" >
                        {props.children[2]}
                  </div>
                  <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1" />
                  <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 syncopate-style">
                        {props.children[1]}
                  </div>
                  <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1" />
            </div>
            </>
      );
}

