var React = require('react');
var ReactDOM = require('react-dom');
export default function  Searchbar (props) {
    return (
      <div>
          <form className="d-flex">
            <input className="form-control me-2" type="search" placeholder="Inserisci l'elemento da cercare" aria-label="Cerca" />
            <button className="btn btn-outline-light" type="submit">Cerca</button>
          </form>
    </div>
    );
}