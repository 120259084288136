/**********************************************************************************************
* DATE CONVERSION ( Detect if ISO date string and convert to date or redeable string )   *
**********************************************************************************************/
const parseDateString = function (value, conversion) {
	let iso; var readable; var year; var month; var day;
	switch (conversion) {
		case 'iso2readable':
			var reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
			if (typeof value === 'string') {
				iso = reISO.exec(value);
				if (iso) { d = new Date(value); year = d.getFullYear(); month = d.getMonth() + 1; day = d.getDate(); return day + "-" + month + "-" + year; }
			}
			break;
		case 'iso2date':
			var reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
			if (typeof value === 'string') {
				iso = reISO.exec(value);
				if (iso) { console.log("is ISO"); return new Date(value); }
			}
			break;
		case 'readable2date':
			var reReadable = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
			if (typeof value === 'string') {
				let readable = reReadable.exec(value);
				if (readable) { var d = new Date(value); /*console.log(d);*/return d; }
				else console.log("is not readable");
			}
			break;
	}
	return value;
}


/*********************************************************************************
* Converte un oggetto in una stringa incapsulata in un array                     * 
* Riceve un ogetto incapsulato in un Array                                       * 
* Ritorna un Array con una stringa derivante dall'unione dei valori dell'oggetto * 
**********************************************************************************/
const objToReactString = (objArray) => {
    let listArray = [];
    let fullname = "";
    objArray.forEach((obj) => {
        let result = "";
        Object.keys(obj).forEach(key => {
            result += " "+ obj[key];
        });
        listArray.push(result.trimStart());
    })
    return listArray;
}

/*********************************************************************************
* Converte un oggetto in un array di valori                                      *
* Riceve un ogetto incapsulato in un Array                                       *
* Ritorna un Array con i valori (stringa) dell'oggetto                           *
*********************************************************************************/
const singleObjectToReactArray = (objArray) => {
    let listArray = [];
    objArray.forEach((obj) => {
        Object.keys(obj).forEach(key => {
            if (key.includes('data')) 
                { 
                    let res = parseDateString(obj[key], 'iso2readable');
                    listArray.push(res);
                }
            else listArray.push(obj[key]);
        });
    });
    return listArray;
}
/**********************************************************************************
* Converte un array di oggetti in un array di array di valori                     *                  
* Riceve un ogetto incapsulato in un Array                                        *
* Ritorna un Array con i valori (stringa) dell'oggetto                            *
**********************************************************************************/
const objectsToReactArray = (objsArray) => {
    let objectsArray = [];
    objsArray.forEach((obj)=> {
        let objArray = singleObjectToReactArray([obj]);
        objectsArray.push(objArray);
    });
    console.log("array of  array: ", objectsArray);
    return objectsArray;
}

export {objToReactString, singleObjectToReactArray, objectsToReactArray }


