
var React = require('react');
var ReactDOM = require('react-dom');

export default function  Mainmenu (props) {


  const handleCommandClick = (str) => () => {
    //console.log("presss!!!", props.appcallbacks );
    props.appcallbacks.operationscallback(str);
  };

  const handleWspaceClick = (str) => () => {
    //console.log("presss wspace!!!");
    props.callbacks.wspacecallback(str);
  };

  return (
      <>
        <nav className="navbar navbar-expand-lg">
     
          
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle text-light" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <h6>Anagrafica</h6>
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><a className="dropdown-item" onClick={handleCommandClick('showallcustomers')}>Visualizza tutti federati</a></li>
                <li><a className="dropdown-item" onClick={handleCommandClick('showcustomer')}>Visualizza un federato</a></li>
                  <li><a className="dropdown-item" href="#">Inserisci federato</a></li>
                  <li><a className="dropdown-item" href="#">Cancella federato</a></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><a className="dropdown-item" href="#">Something else here</a></li>
                </ul>
              </li>
            
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle text-light" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <h6>Aree di lavoro</h6>
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><a className="dropdown-item" onClick={handleWspaceClick('customer')}>Pannello iscritti</a></li>
                  <li><a className="dropdown-item" onClick={handleWspaceClick('payment')}>Pannello pagamenti</a></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><a className="dropdown-item" href="#">Something else here</a></li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled text-light" href="#" tabIndex="-1" aria-disabled="true"><h6>Disabled</h6></a>
              </li>
            </ul>
          </div>
        
      </nav>
  </>
);
}