var React = require('react');
var ReactDOM = require('react-dom');
export default function Footer (props) {
      return (
            <>
            <div className="row align-items-center" style={{height:1+'em'}}></div>
                  <div className="row middark-backcolor">
                        <div className="col" style={{width:100+'%'}}>
                              <span className="footer-style"><strong>TVPROD</strong></span>
                              <p className="footer-style">Copyright 2021 ClipArt</p>
                        </div>
                  </div>
            </>
      );
}
