

/*
Questo codice definisce una funzione che prende in input un parametro chiamato command.
La funzione analizza il valore di command e restituisce una stringa che rappresenta l'URL di un'API corrispondente.
*/
const decodeCommand = (command) => {
    let apiUrl = '';
    // Utilizza uno statement switch per confrontare il valore di "command" con diversi casi
    switch (command) {
      case 'showallcustomers':
        // Se "command" è "showallcustomers", assegna l'URL:"
        apiUrl = 'customers/getall';
        break;
      case 'showcustomer':
        apiUrl = 'customers/getbyid';
        break;
      case 'showcustomerswithdetails':
        apiUrl = 'customers/getalldetails';
        break;
      case 'showallpayments':
        apiUrl = 'payments/getallpayments';
        break;
        case 'showpayment':
          apiUrl = 'payments/getbyid';
          break;  
      case 'showallcustomerpayments':
        apiUrl = 'payments/getbycustomerid';
        break;
      case 'getallgroups':
          apiUrl = 'var/getgroups';
          break;
      default:
        // Se "command" non corrisponde a nessun caso sopra, assegna una stringa vuota
        apiUrl = '';
    }
    // Restituisce l'URL assegnato"
    return apiUrl;
  }

const sendCommand = async function (command, params={}){
    let apiUrl = decodeCommand(command);
    //console.log(apiUrl, params);
    //const response = await fetch('http://localhost:3000/'+apiUrl,  
    const response = await fetch('https://backendmanager.botservice.it/'+apiUrl,  
    {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(params), // body data type must match "Content-Type" header*/
    });

    if(!response.ok) {
        throw new Error('Data coud not be fetched!')
    } else 
    {  
      let r =  response.json();
      return r;
    }
}


const sendSyncCommand = function (command, params={}){
  let apiUrl = decodeCommand(command);
  //console.log(apiUrl, params);
  //return  fetch('http://api-gestionedanza.localhost.it/'+apiUrl,  
  return  fetch('https://backendmanager.botservice.it/'+apiUrl,
  {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(params), // body data type must match "Content-Type" header*/
  });
}

export {sendCommand, sendSyncCommand}


    /*
    const xhr = new XMLHttpRequest();
    //xhr.open("POST", 'http://api-gestionedanza.it/', true);
    xhr.open("GET", 'http://localhost:3000/'+apiUrl, true);
    //Send the proper header information along with the request
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = () => { // Call a function when the state changes.
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        response = xhr.responseText;
        //console.log(response);
        return response;
      }
    }
    xhr.send();
    */

