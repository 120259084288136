var React = require('react');
var ReactDOM = require('react-dom');
import DetailForm from './PaymentDetailsForm';

const PaymentCard =  (props, ref) => {
    console.log("payments: " + props.cardcontent.length);
    return (
        <div ref={ref} >
            {props.cardcontent.map((item) =>
                <div className="card" > 
                    <div className="card-body">
                        <h5 className="card-title text-bg-light">Id: {item[0]}</h5>
                        <h6 className="card-subtitle mb-2 text-muted">Dettagli:</h6>
                        <DetailForm formcontent={item} />
                    </div>
                </div>
            )}
        </div>
    );
}
export default React.forwardRef(PaymentCard);